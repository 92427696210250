import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link
} from '@mui/material';
import React, { ReactElement, ReactNode, useContext } from 'react';
import { H2, Subtitle1 } from './Typography';
import { usePathname } from 'next/navigation';
import { MeContext } from '../providers/Me';
import ProfileButton from './ProfileButton';
import OrgSwitcher from './OrgSwitcher';
import {
  PuzzlePieceIcon,
  FingerprintIcon,
  SettingsIcon,
  AlertSquareIcon
} from '@/src/icons';
import useIsFeatureSwitchEnabled from '../hooks/use_feature_switch';
import { FeatureSwitchKey } from '@natomalabs/common/feature_switches';

const SidebarItems: {
  id: string;
  label: string;
  href: string;
  disabled: boolean;
  icon: ReactElement;
  featureSwitch?: FeatureSwitchKey;
}[] = [
  {
    id: 'directory',
    label: 'Directory',
    href: '/directory',
    disabled: false,
    icon: <FingerprintIcon size="large" />
  },
  {
    id: 'issues',
    label: 'Issues',
    href: '/issues',
    disabled: true,
    icon: <AlertSquareIcon size="large" />,
    featureSwitch: 'displayAlerts'
  },
  {
    id: 'integrations',
    label: 'Integrations',
    href: '/integrations',
    disabled: false,
    icon: <PuzzlePieceIcon size="large" />
  },
  {
    id: 'settings',
    label: 'Settings',
    href: '/settings/general',
    disabled: false,
    icon: <SettingsIcon size="large" />
  }
];

export default function SidebarLayout({ children }: { children: ReactNode }) {
  const { isAuthorized } = useContext(MeContext);
  const isFeatureSwitchEnabled = useIsFeatureSwitchEnabled();
  const pathname = usePathname();
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',
        minHeight: '100%',
        backgroundColor: theme.palette.grey['100']
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          width: theme.spacing(32),
          padding: theme.spacing(1),
          margin: theme.spacing(2),
          borderRadius: theme.spacing(2),
          justifyContent: 'space-between'
        })}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2.5 }}>
          <H2
            sx={(theme) => ({
              fontWeight: 700,
              color: theme.palette.primary.main,
              textAlign: 'center'
            })}
          >
            <OrgSwitcher />
          </H2>
          {isAuthorized && (
            <Box
              sx={(theme) => ({
                borderRadius: theme.spacing(0.5),
                paddingBottom: theme.spacing(2)
              })}
            >
              <List
                sx={(theme) => ({
                  width: '100%',
                  maxWidth: 360,
                  color: theme.palette.grey['900'],
                  padding: theme.spacing(0),
                  '& .MuiListItemButton-root': {
                    borderRadius: theme.spacing(2),
                    margin: '1px'
                  },
                  '& .MuiListItemButton-root:hover': {
                    backgroundColor: theme.palette.grey[200],
                    color: theme.palette.grey['900']
                  },
                  '& .MuiListItemIcon-root': {
                    minWidth: theme.spacing(0),
                    color: theme.palette.grey['900']
                  }
                })}
                component="nav"
              >
                {SidebarItems.map((item) => {
                  const disabled = item.featureSwitch
                    ? !isFeatureSwitchEnabled(item.featureSwitch)
                    : item.disabled;
                  return (
                    <ListItemButton
                      component={Link}
                      disabled={disabled}
                      key={item.id}
                      href={item.href}
                      data-active={item.href.startsWith(
                        `/${pathname.split('/')[1]}`
                      )}
                      sx={(theme) => ({
                        columnGap: 1.5,
                        '&[data-active="true"]': {
                          backgroundColor: theme.palette.primary.main
                        }
                      })}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.label} />
                      {disabled && (
                        <Subtitle1 fontStyle={'italic'}>Coming Soon</Subtitle1>
                      )}
                    </ListItemButton>
                  );
                })}
              </List>
            </Box>
          )}
        </Box>
        <Box>
          <ProfileButton />
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          [`@media (max-width: 1000px)`]: {
            overflow: 'auto'
          }
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            maxHeight: '100vh',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={(theme) => ({
              flexGrow: 1,
              minWidth: '1000px',
              margin: theme.spacing(1),
              [theme.breakpoints.up('xl')]: {
                padding: theme.spacing(3),
                maxHeight: `calc(100% - ${theme.spacing(2)})`
              },
              [theme.breakpoints.down('xl')]: {
                padding: theme.spacing(2),
                maxHeight: `calc(100% - ${theme.spacing(2)})`
              },
              bgcolor: theme.palette.common.white,
              borderRadius: theme.spacing(2)
            })}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
